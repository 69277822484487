<template>
    <div>
        <el-dialog
                :visible.sync="dialog_Visible"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                :before-close="handleClose">
            <template slot="title">
                <div class="header_text">
                    <span>拍摄识别</span>
                </div>
                <div class="stateLink">
                    <div :class="connectstate?'state_normal':'state_defeated'" >
                        <span>{{connectstate?'高拍仪已连接':'高拍仪未连接'}}</span>
                        <img :src="connectstate?chenG:shiBai">
                    </div>
                    <div class="dianji"  @click="getLianJie" style="margin-left:10px;cursor: pointer;margin-top: 3px">
                        <i class="el-icon-refresh graphText"></i>
                        <span class="graphText" >{{tishiText}} </span>
                    </div>


                </div>
            </template>
            <div class="ocr_body"
                 v-loading="discernShow"
                 element-loading-text="识别中请稍后...."
                 element-loading-spinner="el-icon-loading"
                 element-loading-background="rgba(0, 0, 0, 0.8)">
                <div class="orientation" v-if="toggle_photo">
                    <div class="toward_left " style="width:50%;cursor: pointer;text-align:center" @click="towards">
                        <img src="../assets/Vector_f.png">
                        <span class="margin-left-4">左旋转</span>
                    </div>
                    <div class="towards_right " style="width:50%;cursor: pointer;text-align:center" @click="likeRight">
                        <span>右旋转</span>
                        <img class="margin-left-4" src="../assets/Vector_R.png">
                    </div>
                </div>
                <img :src="realImg"  v-if="toggle_photo">
                <div style="width: 640px;height: 480px; display: flex;align-items: center;position: absolute;left: 0;top: 0">
                    <img :src="b64img" style="width: 640px;max-height: 480px"  v-if="!toggle_photo" :class="ocr_imgData.length>0?'tupian':''">
                </div>

                <div class="IdentifyResult" v-if="ocr_imgData.length>0">
                    <div class="showResult">
                        <div class="result_title">识别结果</div>
                        <div v-for="(item,index) in ocr_imgData" :key="index">
                            <div class="title_Name">{{item.label}}</div>
                            <div class="textContent" v-if="item.key=='birth'||item.key=='issueDate' || item.key=='registerDate' ||item.key=='issueDate'||item.key=='foundDate'">{{ts_to_time(item.value)}}</div>
                            <div class="textContent" v-else-if="item.key!=='birth'" >{{item.value}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer" :class="toggle_photo?'test_center':'test_right'">
                <img style="cursor: pointer" src="../assets/photograph.png" @click="takePictures" v-if="toggle_photo">

                <div v-if="!toggle_photo">
                    <img v-if="ocr_imgData.length==0" style="width: 56px;height: 56px; margin-right: 45%;}"  src="../assets/potot_jinzhi.png">
                    <el-button v-if="ocr_imgData.length>0"  class="btn_orange" @click="cancel">取 消</el-button>
                    <el-button v-if="ocr_imgData.length>0" class="btn_orange" @click="rephotograph">重 拍</el-button>
                    <el-button v-if="ocr_imgData.length>0" class="btn_orange" @click="preserves">确 定</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>

    import chenggong from '../assets/chengdian.png'
    import  shibai from '../assets/shibaidian.png'
    export default {
        name: "recognizePhotoView",
        props: {
            realImg: {
                type: String,
                default() {
                    return null
                }
            },
            b64img: {
                type: String,
                default() {
                    return null
                }
            },
            ocr_imgData: {
                type: Array,
                default() {
                    return []
                }
            },
            connectstate:{
                type: String,
                default() {
                    return true
                }
            }
        },
        // watch:{
        //     realImg(val){
        //        this.img_src=require(val)
        //     }
        // },

        data() {
            return {
                dialog_Visible: true,
                state_schedule: true,
                toggle_photo: true,
                discernShow: false,
                // img_src:"",
                connecting:true,
                connecting_link:false,
                chenG:chenggong,
                shiBai:shibai,
                tishiText:"重新连接"
            }
        },
        watch:{
            // connectstate:{
            //     handler(val,old) {
            //         if(val==true){
            //             this.tishiText='重新连接'
            //         }else {
            //             this.tishiText='重新连接'
            //         }
            //
            //     } ,
            // }
        },
        mounted() {

        },
        methods: {
            //关闭弹窗
            handleClose() {
                this.dialog_Visible = false;
                this.$emit('eventPhoto', 'closeVideo')
            },
            // 点击拍照获取图片
            takePictures() {
                this.toggle_photo = false
                this.discernShow = true
                this.$emit('eventPhoto', 'photograph')
            },
            //点击重拍
            rephotograph() {
                console.log(this.ocr_imgData,'有没有变化')
                this.b64img=''
                this.toggle_photo = true
                this.discernShow = false
                this.ocr_imgData = []
                this.$emit('eventPhoto', 'startVideo')
            },
            //取消按钮
            cancel() {
                this.dialog_Visible = false;
                this.$emit('eventPhoto', 'closeVideo')
            },
            //取消蒙尘
            callScratches() {
                this.discernShow = false
            },

            //保存按钮
            preserves() {
                this.dialog_Visible = false;
                this.$emit('eventPhoto', 'saveVideoData')
            },
            towards() {
                this.$emit('eventPhoto', 'left-handed')
            },
            likeRight() {
                this.$emit('eventPhoto', 'right-handed')
            },
            getLianJie(){
                this.connectstate=false
                this.tishiText='连接中。。。'
                this.$emit('eventPhoto', 'reconnection')
            },
            ts_to_time(timestamp) {
                if (typeof timestamp === 'string') {
                    timestamp = Number(timestamp);
                }
                let date = new Date(timestamp);
                let Y = date.getFullYear() + '-';
                let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                let D = date.getDate() + ' '
                return Y + M + D
            },
            oneMinute(){
                this.connectstate=false
                setTimeout(function () {
                    this.connectstate=true
                },1000)
            },
             bianhuaziti(){
                this.tishiText='重新连接'
            }
        }
    }
</script>
<style lang="scss" scoped>
    ::v-deep .el-dialog {
        width: 640px;
        height: 600px;
        max-width: 640px;
        max-height: 600px;
        background: #FFFFFF;
        /* 弹出投影效果 */
        box-shadow: 0px 27px 80px rgba(0, 0, 0, 0.07),
        0px 6.0308px 17.869px rgba(0, 0, 0, 0.0417275),
        0px 1.79553px 5.32008px rgba(0, 0, 0, 0.0282725);
        border-radius: 12px;
        .el-loading-spinner .el-loading-text{
            color: #FFFFFF;
        }
        .el-dialog__header {
            display: flex;
            align-items: center;
            .header_text {
                width: 15%;
                text-align: left;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #333333;
            }

            .stateLink {
                display: flex;

                .state_normal {
                    width: 140px;
                    height: 24px;
                    background: rgba(21, 153, 0, 0.1);
                    border-radius: 3px;
                    line-height: 24px;
                    text-align: center;
                    color: rgba(21, 153, 0, 1);
                }

                .state_defeated {
                    width: 140px;
                    height: 24px;
                    background: rgba(255, 26, 26, 0.1);
                    border-radius: 3px;
                    line-height: 24px;
                    text-align: center;
                    color: rgba(255, 26, 26, 1);
                    margin-right: 10px;
                }

                .graphText {
                    color: rgba(255, 137, 36, 1);
                }
            }
        }

        .el-dialog__body {
            background: rgba(0, 0, 0, 0.2);
            height: 480px;
            padding: 0;

            .ocr_body {
                position: relative;

                .orientation {
                    position: absolute;
                    width: 184px;
                    height: 32px;
                    left: 230px;
                    top: 440px;
                    background: rgba(0, 0, 0, 0.3);
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    color: #FFFFFF;
                    z-index:10000 !important;
                    .toward_left, .towards_right {
                        height: 32px;
                        line-height: 32px;
                    }
                }
            }

            .IdentifyResult {
                position: absolute;
                z-index: 2000;
                right: 0;
                bottom: 0;
                height: 480px;
                width: 640px;
                left: 0px;
                top: 0px;
                /*line-height: 480px;*/
                background: rgba(0, 0, 0, 0.6);

                .showResult {
                    width: 30%;
                    margin-left: 70%;
                    height: 400px;
                    color: #FFFFFF;
                    overflow: auto;

                    .result_title {
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        margin-top: 50px;
                    }

                    .title_Name {
                        color: #FFFFFF;
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        margin-top: 20px;
                    }

                    .textContent {
                        color: #FFFFFF;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        font-family: 'PingFang SC';
                        margin-top: 20px;
                    }
                }

            }

            .el-loading-mask {
                height: 480px;
                width: 640px;
                left: 0px;
                top: 0px;
                background: rgba(0, 0, 0, 0.6) !important;
            }
        }

        .el-dialog__footer {
            padding: 5px 20px 20px;
        }

        .test_right {
            text-align: right;

        }

        .test_center {
            text-align: center;
        }

        .btn_orange {
            background: rgba(255, 149, 3, 0.1) !important;
            border-radius: 3px !important;
            color: #FF9503 !important;
            border: none;
        }

        .btn_orange:hover {
            background: #FF9503 !important;
            color: #FFFFFF !important;
        }
        .tupian{
            /*margin-top: 35px;*/
        }
        .dianji:hover{
            background:rgba(255, 137, 36,0.1);
        }
    }
</style>
