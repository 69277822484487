<template>
  <div>1111111111</div>
</template>

<script>
export default {
  name: "recognizePhotoView1",
};
</script>

<style scoped></style>
