(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("ELEMENT"));
	else if(typeof define === 'function' && define.amd)
		define("recognizePhotoPlugin", ["ELEMENT"], factory);
	else if(typeof exports === 'object')
		exports["recognizePhotoPlugin"] = factory(require("ELEMENT"));
	else
		root["recognizePhotoPlugin"] = factory(root["ELEMENT"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__5__) {
return 