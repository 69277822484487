import recognizePhotoView from "./components/recognizePhotoView";
import recognizePhotoView1 from "./components/recognizePhotoView1";
import './css/form-style.scss'
import ElementUI from 'element-ui';

const vueComponent = {
    install: (app) => {
        app.use(ElementUI);
        app.config.productionTip = false;
        app.component('photoView', recognizePhotoView)
        app.component('photoView1', recognizePhotoView1)
    }
}
export default vueComponent;
