var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialog_Visible,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialog_Visible = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "header_text" }, [
              _c("span", [_vm._v("拍摄识别")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "stateLink" }, [
              _c(
                "div",
                { class: _vm.connectstate ? "state_normal" : "state_defeated" },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.connectstate ? "高拍仪已连接" : "高拍仪未连接")
                    ),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    attrs: { src: _vm.connectstate ? _vm.chenG : _vm.shiBai },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dianji",
                  staticStyle: {
                    "margin-left": "10px",
                    cursor: "pointer",
                    "margin-top": "3px",
                  },
                  on: { click: _vm.getLianJie },
                },
                [
                  _c("i", { staticClass: "el-icon-refresh graphText" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "graphText" }, [
                    _vm._v(_vm._s(_vm.tishiText) + " "),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.discernShow,
                  expression: "discernShow",
                },
              ],
              staticClass: "ocr_body",
              attrs: {
                "element-loading-text": "识别中请稍后....",
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(0, 0, 0, 0.8)",
              },
            },
            [
              _vm.toggle_photo
                ? _c("div", { staticClass: "orientation" }, [
                    _c(
                      "div",
                      {
                        staticClass: "toward_left ",
                        staticStyle: {
                          width: "50%",
                          cursor: "pointer",
                          "text-align": "center",
                        },
                        on: { click: _vm.towards },
                      },
                      [
                        _c("img", {
                          attrs: { src: require("../assets/Vector_f.png") },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "margin-left-4" }, [
                          _vm._v("左旋转"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "towards_right ",
                        staticStyle: {
                          width: "50%",
                          cursor: "pointer",
                          "text-align": "center",
                        },
                        on: { click: _vm.likeRight },
                      },
                      [
                        _c("span", [_vm._v("右旋转")]),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "margin-left-4",
                          attrs: { src: require("../assets/Vector_R.png") },
                        }),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.toggle_photo
                ? _c("img", { attrs: { src: _vm.realImg } })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "640px",
                    height: "480px",
                    display: "flex",
                    "align-items": "center",
                    position: "absolute",
                    left: "0",
                    top: "0",
                  },
                },
                [
                  !_vm.toggle_photo
                    ? _c("img", {
                        class: _vm.ocr_imgData.length > 0 ? "tupian" : "",
                        staticStyle: { width: "640px", "max-height": "480px" },
                        attrs: { src: _vm.b64img },
                      })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _vm.ocr_imgData.length > 0
                ? _c("div", { staticClass: "IdentifyResult" }, [
                    _c(
                      "div",
                      { staticClass: "showResult" },
                      [
                        _c("div", { staticClass: "result_title" }, [
                          _vm._v("识别结果"),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.ocr_imgData, function (item, index) {
                          return _c("div", { key: index }, [
                            _c("div", { staticClass: "title_Name" }, [
                              _vm._v(_vm._s(item.label)),
                            ]),
                            _vm._v(" "),
                            item.key == "birth" ||
                            item.key == "issueDate" ||
                            item.key == "registerDate" ||
                            item.key == "issueDate" ||
                            item.key == "foundDate"
                              ? _c("div", { staticClass: "textContent" }, [
                                  _vm._v(_vm._s(_vm.ts_to_time(item.value))),
                                ])
                              : item.key !== "birth"
                              ? _c("div", { staticClass: "textContent" }, [
                                  _vm._v(_vm._s(item.value)),
                                ])
                              : _vm._e(),
                          ])
                        }),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              class: _vm.toggle_photo ? "test_center" : "test_right",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.toggle_photo
                ? _c("img", {
                    staticStyle: { cursor: "pointer" },
                    attrs: { src: require("../assets/photograph.png") },
                    on: { click: _vm.takePictures },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.toggle_photo
                ? _c(
                    "div",
                    [
                      _vm.ocr_imgData.length == 0
                        ? _c("img", {
                            staticStyle: {
                              width: "56px",
                              height: "56px",
                              "margin-right": "45%",
                            },
                            attrs: {
                              src: require("../assets/potot_jinzhi.png"),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ocr_imgData.length > 0
                        ? _c(
                            "el-button",
                            {
                              staticClass: "btn_orange",
                              on: { click: _vm.cancel },
                            },
                            [_vm._v("取 消")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ocr_imgData.length > 0
                        ? _c(
                            "el-button",
                            {
                              staticClass: "btn_orange",
                              on: { click: _vm.rephotograph },
                            },
                            [_vm._v("重 拍")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ocr_imgData.length > 0
                        ? _c(
                            "el-button",
                            {
                              staticClass: "btn_orange",
                              on: { click: _vm.preserves },
                            },
                            [_vm._v("确 定")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }